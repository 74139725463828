import { defineStore } from 'pinia'
import {AccountApi} from "~/gen/openapi/portalService";
import {ACCOUNTANTS_USER_TYPE_ID} from "~/store/constants/accountants";

interface State {
    data: Array<any>,
    isFetching: boolean,
    timeLastFetch: any,
}

export const useAccountantsStore = defineStore('accountants', {
  state: (): State => ({
      data: [],
      isFetching: false,
      timeLastFetch: null,
	}),
	getters: {
        all(state) {
            const accountants = state.data.slice(0)
            return accountants.sort((a, b) => a.Name.localeCompare(b.Name))
        },
        byId(state) {
            return (id: any) => {
                return state.data.find((accountant) => accountant.Id === id)
            };
        },
	},
	actions: {
      async fetch() {
          // If already fetched in last 5 min (5m * 60s * 1000ms = 300000) skip the fetch
          if (this.timeLastFetch && this.timeLastFetch > Date.now() - 300000) {
              return
          }
          const {$axios, $config} = useNuxtApp()
          const accountApi = new AccountApi(undefined, $config.public.apiBaseHost, $axios);
          this.timeLastFetch = Date.now()
          this.isFetching = true

          try {
              this.data = (await accountApi.accountGetAccountsByType(ACCOUNTANTS_USER_TYPE_ID)).data
          } catch (error) {
              this.data = []
              console.error(error)
          } finally {
              this.isFetching = false
          }
      }
	}
})
