<template>
	<modal :show="show" :padded="false" @close="close">
		<template #header>
			<div v-if="activeAccountant">{{ $t('CHANGE_ACCOUNTANT') }}</div>
			<div v-else>{{ $t('CHOOSE_AN_ACCOUNTANT') }}</div>
		</template>
		<template #default>
      <form class="stacked-content" @submit.stop.prevent="changeAccountant">
        <div class="stacked-content__fixed modal-search">
          <label
              class="visuallyhidden"
              :for="`modal-change-accountant_${_uid}_filterSearch`"
          >{{ $t('FILTER_ACCOUNTANTS') }}</label
          >
          <input
              :id="`modal-change-accountant_${_uid}_filterSearch`"
              ref="filterSearch"
              v-model="accountantFilter"
              class="modal-search__input"
              :disabled="isPendingSaveAccount"
              type="search"
              size="70"
          />
          <div class="modal-search__icon icon">
            <svg-icon width="24" height="24" name="search" />
          </div>
          <transition name="transition--fade">
            <button
                v-if="accountantFilter != ''"
                class="modal-search__btn btn-icon--only"
                :disabled="isPendingSaveAccount"
                @click.stop.prevent="clearFilter"
            >
              <div class="icon">
                <svg-icon width="16" height="16" name="close-16" />
              </div>
              <span class="visuallyhidden">{{ $t('REMOVE_FILTER') }}</span>
            </button>
          </transition>
        </div>
        <transition-group
            class="radio-list radio-list--border stacked-content__flexible"
            tag="span"
            name="transition--list"
        >
          <div
              v-for="accountant in accountants"
              :key="accountant.Id"
              class="radio-list__item"
          >
            <i
                v-if="accountant.Id === activeAccountantId"
                class="radio-list__icon icon"
            >
              <svg-icon width="16" height="16" name="check" /></i>
            <input
                :id="`modal-change-accountant_${_uid}_choice_${accountant.Id}`"
                v-model="selectedAccountantId"
                class="radio-list__input"
                type="radio"
                :name="`accountant_${_uid}_choice`"
                :value="accountant.Id"
                :disabled="isPendingSaveAccount"
            />
            <label
                class="radio-list__label"
                :for="`modal-change-accountant_${_uid}_choice_${accountant.Id}`"
            >{{ accountant.Name }}</label>
          </div>
        </transition-group>
        <button
            class="visuallyhidden"
            type="submit"
            tabindex="-1"
            :disabled="!canChangeAccountant || isPendingSaveAccount"
        >
				<span v-if="isPendingSaveAccount">{{ $t('SAVING_CHANGES_PLEASE_WAIT') }}</span
        ><span v-else-if="activeAccountantId && !canChangeAccountant">{{
            $t('CHOOSE_A_DIFFERENT_ACCOUNTANT_FROM_THE_LIST')
          }}</span
        ><span v-else-if="!canChangeAccountant">{{
            $t('CHOOSE_AN_ACCOUNTANT_FROM_THE_LIST')
          }}</span
        ><span v-else-if="activeAccountant">{{
            $t('CHANGE_TO_CHOSEN_ACCOUNTANT')
          }}</span
        ><span v-else>{{ $t('CHOOSE_THIS_ACCOUNTANT') }}</span>
        </button>
      </form>
    </template>
		<template #footer>
			<button
				class="btn btn--primary btn--block"
				:disabled="!canChangeAccountant || isPendingSaveAccount"
				@click.stop.prevent="changeAccountant"
			>
				<span v-if="isPendingSaveAccount"
					><i class="loader"></i> {{ $t('SAVING_CHANGES_PLEASE_WAIT') }}</span
				><span v-else-if="activeAccountantId && !canChangeAccountant">{{
					$t('CHOOSE_A_DIFFERENT_ACCOUNTANT_FROM_THE_LIST')
				}}</span
				><span v-else-if="!canChangeAccountant">{{
					$t('CHOOSE_AN_ACCOUNTANT_FROM_THE_LIST')
				}}</span
				><span v-else-if="activeAccountant">{{
					$t('CHANGE_TO_CHOSEN_ACCOUNTANT')
				}}</span
				><span v-else>{{ $t('CHOOSE_THIS_ACCOUNTANT') }}</span>
			</button>
			<button class="btn--link btn--block" @click="close">
				{{ $t('CANCEL') }}
			</button>
		</template>
	</modal>
</template>
<script>
import {mapState} from "pinia";
import Modal from '~/components/Modal.vue'
import {useAccountantsStore} from "~/stores/accountants";
import {notify} from "@gm/components";
import {useBankIDStore} from "~/stores/bankID";
import {useAccountService} from "~/services/account";

export default {
	name: 'ModalChangeAccountant',
	components: {
		Modal,
	},
	props: {
		activeAccountantId: {
			type: String,
		},
		// Use emitChanges to return Account name and Id instead of saving it
		emitChanges: {
			type: Boolean,
			default: false,
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
  setup() {
    const { isPendingSaveAccount, saveAccount } = useAccountService()

    return {
      isPendingSaveAccount,
      saveAccount
    }
  },
	data() {
		return {
			accountantFilter: '',
			selectedAccountantId: null,
		}
	},
	computed: {
    ...mapState(useAccountantsStore, ['byId', 'all']),
		activeAccountant() {
			return this.activeAccountantId
				? this.byId(this.activeAccountantId)
				: null
		},
		accountants() {
			return this.all.filter(
				(accountant) =>
					!this.accountantFilter ||
					accountant.Name.toLowerCase().includes(this.accountantFilter.toLowerCase())
			)
		},
		canChangeAccountant() {
			return (
				this.selectedAccountantId &&
				this.selectedAccountantId != this.activeAccountantId
			)
		},
	},
	watch: {
		show() {
			this.setFocus()
		},
	},
	mounted() {
		this.selectedAccountantId = this.activeAccountantId
			? this.activeAccountantId
			: null

    const accountantsStore = useAccountantsStore();
    accountantsStore.fetch()
	},
	methods: {
		async changeAccountant() {
      if (!this.canChangeAccountant) {
        return
      }

      const newAccountant = this.byId(
          this.selectedAccountantId
      )
      const newAccountantName =
          newAccountant && newAccountant.hasOwnProperty('Name')
              ? newAccountant.Name
              : ''
      const data = {
        Id: this.$route.params.accountId,
        AccountantId: this.selectedAccountantId,
        AccountantName: newAccountantName,
      }

      if (this.emitChanges) {
        this.$emit('update:modelValue', data)
        this.close()
        return
      }
      const bankIDStore = useBankIDStore()

      await bankIDStore.getTokensRefreshIfNeeded()
      this.saveAccount({
        Account: data,
        AccessToken: bankIDStore.tokens?.access_token,
        IdToken: bankIDStore.tokens?.id_token
      }, {
        onSuccess: () =>  {
          notify(this.$t('SUCCESS_ACCOUNTANT_ADDED', {
            name: newAccountantName,
          }).toString(), {
            type: 'success',
            icon: 'check',
          })},
        onError: (error) => {
          console.error(error)
          notify(this.$t('ERROR_ACCOUNTANT_ADDED').toString(), {
            type: 'error',
            icon: 'x'
          })
          this.close()
        }
      })
    },
		clearFilter() {
			this.accountantFilter = ''
			this.$refs.filterSearch.focus()
		},
		close() {
			this.$emit('close')
		},
		setFocus() {
			if (this.activeAccountant) {
				setTimeout(() => {
					const activeElement = document.getElementById(
						`modal-change-accountant_${this._uid}_choice_${this.activeAccountantId}`
					)

					if (activeElement) {
						activeElement.focus()
					}
				}, 100)
			}
		},
	},
}
</script>
